import styled from 'styled-components'

export default styled.div`
  height: 100%;
  background-color: #1e1e1e;
  .card-wrapper {
    width: 100%;
    height: 100%;
    .card-title {
      font-family: 'Prompt';
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #f2f2f2;
      width: 100%;
      height: 32px;
      padding-left: 16px;
    }
    .card-content {
      width: 100%;
      height: calc(100% - 33px); /* 33px from header 32px and red line 1 px  */
    }
  }
`
