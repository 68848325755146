import React from 'react'
import PropTypes from 'prop-types'

import CardStyled from './styled'

class CardWrapper extends React.PureComponent {
  renderCardTitle() {
    return (
      <div className="card-title flex align-items-center" id={`${this.props.id}-title`} data-test={`${this.props.id}-title`}>
        {this.props.title}
      </div>
    )
  }

  renderLine() {
    return <div className="red-line" id={`${this.props.id}-line`} data-test={`${this.props.id}-line`} />
  }

  renderCardContent() {
    return (
      <div className="card-content" id={`${this.props.id}-content`} data-test={`${this.props.id}-content`}>
        {this.props.children}
      </div>
    )
  }

  getContent() {
    return (
      <div className="card-wrapper" id={`${this.props.id}-content-wrapper`} data-test={`${this.props.id}-content-wrapper`}>
        {this.renderCardTitle()}
        {this.renderLine()}
        {this.renderCardContent()}
      </div>
    )
  }

  render() {
    return (
      <CardStyled className={this.props.className} id={`${this.props.id}-card-wrapper`} data-test={`${this.props.id}-card-wrapper`}>
        {this.getContent()}
      </CardStyled>
    )
  }
}

CardWrapper.defaultProps = {
  title: '',
}

CardWrapper.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]).isRequired,
}

export default CardWrapper
